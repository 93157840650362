body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: -webkit-gradient(linear,
      right top, left top,
      from(rgba(43, 59, 74, 1)),
      to(rgba(140, 154, 136, 1))) no-repeat fixed;
  background: -o-linear-gradient(right,
      rgba(43, 59, 74, 1) 0%,
      rgba(140, 154, 136, 1) 100%) no-repeat fixed;
  background: linear-gradient(to left,
      rgba(43, 59, 74, 1) 0%,
      rgba(140, 154, 136, 1) 100%) no-repeat fixed;
  text-align: center;
  color: #E2E6E9;
}

.header {
  margin: auto;
}

.app-title {
  margin-top: 20px;
  font-size: 4rem;
  font-family: "Game of Thrones";
  letter-spacing: 0.8rem;
}

.app-subtitle {
  font-size: 2rem;
  font-family: "Game of Thrones";
  letter-spacing: 0.8rem;
}